var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c8ebaa95f7de1355716da627ba5e4f67d1bca5dd"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

const Sentry = require('@sentry/nextjs');

Sentry.init({
  enabled: true,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.LND_ENV,
  release: process.env.NEXT_PUBLIC_COMMIT_SHA,
});
